<template>
  <div class="card card-body pb-1 ">
<!--    <h2 class="h4 mb-4">Refine Results</h2>-->
    <status-filter :filters="filters"></status-filter>
  </div>
</template>

<script>
import StatusFilter from "./SidebarFilters/StatusFilter";

export default {
  name: "SidebarFilters",
  components: {StatusFilter},
  props: ['filters']
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">All Jobs</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Results -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb-->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Results grid -->
        <div class="row">
          <div class="col-md-3">
            <!-- Search -->
            <input type="text" v-model="searchQuery" class="form-control mb-3" placeholder="Search jobs...">
            <!-- / Search -->
            
            <sidebar-filters :filters="filters"></sidebar-filters>
          </div>
          <div class="col-md-9">
            <!-- Is Loading -->
            <div v-if="isLoading" class="text-center">
              <spinner></spinner>
            </div>
            <!-- / Is Loading -->

            <!-- No results -->
            <no-results v-if="!(isLoading) && jobsToShow.length === 0">
              No jobs to show
            </no-results>
            <!-- / No results -->

            <!-- Results wrapper -->
            <div v-if="!(isLoading) && jobsToShow.length">

              <!-- Topbar -->
              <!--              <div class="d-sm-flex align-items-center justify-content-between pb-4 mb-sm-2">-->
              <!--&lt;!&ndash;                <div class="d-flex align-items-center">&ndash;&gt;-->
              <!--&lt;!&ndash;                  <label class="fs-sm me-2 pe-1 text-nowrap" for="sorting"><i class="fi-arrows-sort mt-n1 me-2"></i>Sort by:</label>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <select class="form-select form-select-sm" id="sorting">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <option>Newest</option>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <option>Popular</option>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <option>Highest Salary</option>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </select>&ndash;&gt;-->
              <!--&lt;!&ndash;                </div>&ndash;&gt;-->
              <!--                <div class="text-muted fs-sm text-nowrap"><i class="fi-briefcase fs-base mt-n1 me-2"></i>{{ results.total }} {{ results.total == 1 ? 'result' : 'results' }}</div>-->
              <!--              </div>-->
              <!-- / Topbar -->

              <!-- Advisor: Results -->
              <div v-if="isAdvisor">
                <advisor-job-result-card v-for="(job, i) in jobsToShow" :key="i"
                                         class="mb-3"
                                         :job="job">
                </advisor-job-result-card>
              </div>
              <!-- / Advisor: Results -->

              <!-- Provider: Results -->
              <div v-if="isProvider">
                <provider-job-result-card v-for="(job, i) in jobsToShow" :key="i"
                                          class="mb-3"
                                          :job="job">
                </provider-job-result-card>
              </div>
              <!-- / Provider: Results -->

              <!-- Client: Results -->
              <div v-if="isClient">
                <client-job-result-card v-for="(job, i) in jobsToShow" :key="i"
                                        class="mb-3"
                                        :job="job">

                </client-job-result-card>
              </div>
              <!-- / Client: Results -->
            </div>
            <!-- / Results wrapper -->

          </div>
        </div>
        <!-- / Results grid -->
      </div>
    </section>
    <!-- / Results -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
const _ = require("lodash");
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import NoResults from "../../components/NoResults";
import Forms from "../../mixins/Forms";
import AdvisorJobResultCard from "./Index/AdvisorJobResultCard";
import ProviderJobResultCard from "./Index/ProviderJobResultCard";
import ClientJobResultCard from "./Index/ClientJobResultCard";
import SidebarFilters from "./Index/SidebarFilters";
import Jobs from "../../mixins/Jobs";

export default {
  name: "Index",
  mixins: [Forms, Jobs],
  components: {
    SidebarFilters,
    AdvisorJobResultCard,
    ProviderJobResultCard,
    ClientJobResultCard,
    Breadcrumb,
    Spinner,
    NoResults
  },
  created() {
    if (this.$route.query.status) {
      let status = this.$route.query.status;
      if (status === 'quoting') {
        this.filters['status'] = 0;
      }
      if (status === 'active') {
        this.filters['status'] = 1;
      }
      if (status === 'complete') {
        this.filters['status'] = 2;
      }
    }
  },
  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "Jobs",
          active: true,
        },
      ],
      filters: {
        status: null
      },
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAdvisor: 'isAdvisor',
      isProvider: 'isProvider',
      isClient: 'isClient'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs',
      isLoading: 'isLoading'
    }),

    jobsToShow() {
      if (this.jobs) {
        let jobs = this.jobs;

        if ('status' in this.filters && this.filters.status !== null) {
          jobs = _.filter(jobs, job => {
            return job.status == this.filters.status;
          })
        }

        let jobsSearched = this.searchJobs(jobs, this.searchQuery);
        return _.sortBy(jobsSearched, 'created_at').reverse(); // sort with newest first
      }
      return [];
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <nav class="" aria-label="Breadcrumb">
        <ol class="breadcrumb" :class="{'breadcrumb-light': type == 'light'}">
            <li v-for="(crumb, i) in crumbs" class="breadcrumb-item" :class="{'active': crumb.active === true}" :key="i">
                <a v-if="crumb.active !== true" @click.prevent="() => $router.push(crumb.href)" :href="crumb.href">{{ crumb.label }}</a>
                <template v-else>{{ crumb.label }}</template>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    props: ['crumbs', 'type'],
    setup() {
        
    },
}
</script>


<style>

</style>